@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Cutive+Mono&display=swap');

body {
  margin: 0;
  font-family: 'Amatic SC', cursive;
  font-family: 'Cutive Mono', monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
